import store from "@/store";
import { iOS, macOS } from "@/util/mobile";
import router from "@/router";
import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import i18n from "@/i18n";

/** @remarks menu functions */
export function unreadAnnouncementNumber(): number {
  const totalAnnouncementNumber = store.state.data.announcementNumber;
  if (totalAnnouncementNumber === undefined) {
    store.commit("data/setreadAnnouncementNumber", 0);
    return 0;
  }
  const readAnnouncementList: PortalAdapterApi.ResponseGetNotice[] = JSON.parse(localStorage.announcementList || "[]");
  store.commit("data/setReadAnnouncementNumber", readAnnouncementList.length);
  return totalAnnouncementNumber - readAnnouncementList.length;
}
export function toggleMenuPanel() {
  store.commit("ui/stopElementScrolling", true);
  store.commit("ui/showMenuPanel", true);
}
export function questionaireBtnClicked() {
  switch (store.state.data.language) {
    case "ja-JP": {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSdic-UZmsQN67MZUYDeqYendxfd5iWdbwzOkHLbJc5DGTBX3g/viewform",
        "_blank",
      );
      break;
    }
    case "en-US": {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSdVRD28by1J2aZOYBV0_WurlW89yvgb_uIUjAzq2INJWg6c7Q/viewform",
        "_blank",
      );
      break;
    }
    case "zh-TW": {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSc_wlqbxkZ1wOhbKZ_OECCizaylSOssy3cZGJ1SzKJJNqKRAw/viewform",
        "_blank",
      );
      break;
    }
    case "zh-CN": {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSdXYlhcXdSMABI25zSj8JfXnt4fMUGwe74CJwFtKwO6brif7g/viewform",
        "_blank",
      );
      break;
    }
    default: {
      // ja-JP
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSdic-UZmsQN67MZUYDeqYendxfd5iWdbwzOkHLbJc5DGTBX3g/viewform",
        "_blank",
      );
      break;
    }
  }
}
import "unfetch/polyfill"; // polyfill json fetch
type FaqType = {
  title: string;
  section: { q: string; a: string }[];
};
export async function faqBtnClicked(): Promise<FaqType[]> {
  switch (store.state.data.language) {
    case "ja-JP": {
      return await (await window.fetch("/faq.json")).json();
    }
    case "en-US": {
      return await (await window.fetch("/faq-en.json")).json();
    }
    case "zh-TW": {
      return await (await window.fetch("/faq-tw.json")).json();
    }
    case "zh-CN": {
      return await (await window.fetch("/faq-cn.json")).json();
    }
    case "ko-KR": {
      return await (await window.fetch("/faq-ko.json")).json();
    }
    default: {
      return await (await window.fetch("/faq.json")).json();
    }
  }
}

/** @remarks token exchanged */

export function hasAccessToken(): boolean {
  const accessToken: string = localStorage.getItem("session/tokens/accessToken") || "";
  if (accessToken === null || accessToken.length === 0) {
    return false;
  } else {
    return true;
  }
}

/** @remarks 3'rd party links */
export function openIncNavi() {
  if (iOS()) {
    // const url = `incnavi://`;
    const url = `https://apps.apple.com/au/app/inclusive-navi/id1423940361`;
    window.open(url);
  } else {
    const url = macOS()
      ? `https://apps.apple.com/au/app/inclusive-navi/id1423940361`
      : `https://play.google.com/store/apps/details?id=hulop.navcog.shimizu.IncNavi&hl=ja_JP`;
    window.open(url);
  }
}
export function navOnGoogleMaps(selectedPlaceName: string, shopLocation: PortalAdapterApi.Location) {
  const currentLocation = store.state.data.location;
  if (shopLocation === undefined || selectedPlaceName === undefined) return;
  const url = `https://www.google.com/maps/dir/?api=1&destination=${shopLocation.lat},${shopLocation.lng}&origin=${currentLocation.lat},${currentLocation.lng}`;
  window.open(url);
}

/** @remarks SEO titles tags */
export function replaceTitleTag(title: string, genre?: string, categoryList?: string[]): string {
  const formatedTitle: string = `${title} - 
        ${genre === undefined ? "" : genre + "/"}
        ${categoryList === undefined ? "" : categoryList.join("/")} 
        [VACAN Maps（バカン）]`;

  // set titles
  document.title = formatedTitle;
  const ogTitle: HTMLElement | null = document.querySelector('meta[property="og:title"]');
  const twitterTitle: HTMLElement | null = document.querySelector('meta[property="twitter:title"]');
  if (ogTitle !== null) {
    ogTitle.setAttribute("content", title);
  }
  if (twitterTitle !== null) {
    twitterTitle.setAttribute("content", title);
  }

  return formatedTitle;
}
export function replaceDescriptionMeta(content?: string) {
  if (content === undefined || content === "") return;

  const desc: HTMLElement | null = document.querySelector('meta[name="description"]');
  const ogDesc: HTMLElement | null = document.querySelector('meta[property="og:description"]');
  const twitterDesc: HTMLElement | null = document.querySelector('meta[property="twitter:description"]');

  if (desc !== null) desc.setAttribute("content", content);
  if (ogDesc !== null) ogDesc.setAttribute("content", content);
  if (twitterDesc !== null) twitterDesc.setAttribute("content", content);
}

/** @remarks languages */
export function toLongLang(short: string): "en-US" | "zh-TW" | "zh-CN" | "ko-KR" | "ja-JP" {
  switch (short) {
    case "en":
      return "en-US";
    case "tw":
      return "zh-TW";
    case "cn":
      return "zh-CN";
    case "ko":
      return "ko-KR";
    default:
      return "ja-JP";
  }
}
export function toShortLang(long: string): "en" | "tw" | "cn" | "ko" | undefined {
  switch (long) {
    case "en-US":
      return "en";
    case "zh-TW":
      return "tw";
    case "zh-CN":
      return "cn";
    case "ko-KR":
      return "ko";
    default:
      return undefined;
  }
}

/** @remarks images 関連 */
export function getCoverImageUrl(image: PortalAdapterApi.PublicImage): string {
  return image?.thumbnail === undefined || image?.thumbnail.url === "" ? image.origin.url : image?.thumbnail.url;
}
export function getCoverImageWebpUrl(image: PortalAdapterApi.PublicImage): string {
  return image?.thumbnail === undefined || image?.thumbnail.url === ""
    ? image.origin.webp_url
    : image?.thumbnail.webp_url;
}

export function convertTimeFromDistance(meter: number) {
  const hour = meter / 1000 / 3.6; // 人類平均步行速率 3.6km/hour
  const minus = Math.floor(hour * 60);
  return minus <= 60
    ? `${i18n.t("Distance.about", { minus })}`
    : `${i18n.t("Distance.about", { minus: 60 })} ${i18n.t("Distance.more")}`;
}

/** @remarks from path UI導線処理 */

export function parameterizedURLQuery(obj: { [Key: string]: string } | { [Key: string]: [string] }): string {
  const merged = Object.keys(obj)
    .map(function (k) {
      const value: string | [string] | undefined = obj[k];
      if (value === undefined) {
        delete obj[k];
      } else if (k === "genreCategoryList" && typeof value === "object" && value.length > 0) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(value.join(","));
      } else if (value.length === 0) {
        delete obj[k];
      } else if (typeof value === "string") {
        return encodeURIComponent(k) + "=" + encodeURIComponent(value);
      }
    })
    .filter((value) => value !== undefined);
  return merged.join("&");
}

// Modified from geodatasource.com
// https://www.geodatasource.com/developers/javascript
export function distanceFromLatLng(loc1: PortalAdapterApi.Location, loc2: PortalAdapterApi.Location): number {
  if (loc1.lat === loc2.lat && loc1.lng === loc2.lng) return 0;
  const radLat1 = (Math.PI * loc1.lat) / 180;
  const radLat2 = (Math.PI * loc2.lat) / 180;
  const theta = loc1.lng - loc2.lng;
  const radTheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = ((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515;
  // km
  return dist * 1.609344;
}

export function genreCategoryListToString(genreCategoryList?: string[]): string | undefined {
  return genreCategoryList === undefined
    ? undefined
    : genreCategoryList.length > 0
    ? genreCategoryList.join(",")
    : genreCategoryList[0];
}

export function genreCategoryListStringToArray(str?: string): string[] | undefined {
  return str === undefined ? undefined : str.includes(",") ? str.split(",") : [str];
}

export function getGenerMapZoomFromParams(): {
  genre?: string;
  mapZoom?: string;
  genreCategoryListStr?: string;
} {
  // /area/:areaName/:genre/:mapZoom
  const mapZoom: string | undefined = router.currentRoute.params.mapZoom;
  let genre: string | undefined = router.currentRoute.params.genre;
  let genreCategoryListStr: string | undefined = router.currentRoute.params.genreCategoryList;

  // for routes update with /area format
  // /area/:areaName/?genre=&genreCategoryList=
  if (genre === undefined) {
    genre = router.currentRoute.query.genre as string | undefined;
  }

  if (genreCategoryListStr === undefined) {
    genreCategoryListStr = router.currentRoute.query.genreCategoryList as string | undefined;
  }
  return { genre, mapZoom, genreCategoryListStr };
}

/**
 * @remarks vacancy error
 * @description Calculate error times of getting vacancyList
 * and popup banner and alerts to users. The erros could be endpoint 404, 500
 * errors or one of vacancyData failed
 * */
export function showVacancyErrorBannerTitle(): string {
  return store.getters["data/isNotNormalMap"]
    ? (i18n.t("Banner.vacancyError.title") as string)
    : (i18n.t("Banner.vacancyError.title2") as string);
}

export function vacancyErrorBannerBtnClicked() {
  router.push("/").catch(() => console.error("go to top error"));
}

export function getPlaceIdHashList(placeList: PortalAdapterApi.ResponseGetPublicPlace[]): string[] {
  return placeList.map((place) => place.placeIdHash);
}

export function fetchAnnouncementNumber() {
  store
    .dispatch("data/getAnnouncementNumber")
    .then((res: number) => {
      store.commit("data/setAnnouncementNumber", res);
    })
    .catch(() => {
      console.error(`get announcement number error`);
    });
}

/** @remarks Prepare storage for announcement view */
export function initAnnouncementList() {
  if (typeof Storage !== "undefined") {
    if (!localStorage.announcementList) {
      localStorage.announcementList = "";
    }
  } else {
    console.error("Your browser is not supported to HTML5 storage");
  }
}

// find displayText on area view
export function getPrefectureDisplayName(
  selectedPrefecture: string,
  prefectureList: PortalAdapterApi.ResponseGetTopAreaList,
): string | undefined {
  return prefectureList.find((prefecture) => prefecture.areaGroupName === selectedPrefecture)?.displayText;
}
