import Vue from "vue";
import { init as SentryInit } from "@sentry/vue";

if (process.env.VUE_APP_MODE !== "development") {
  SentryInit({
    Vue,
    dsn:
      "https://4fd19b3a166f4806b8e3f0bb02c56675@o516217.ingest.sentry.io/5780065",
    environment: process.env.VUE_APP_MODE,
    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate: 0.05,
  });
}
