export function setLocalStorage(key: string, obj: any) {
  if (typeof obj === "string") {
    return localStorage.setItem(key, obj);
  }
  localStorage.setItem(key, JSON.stringify(obj));
}

export function getLocalStorage(key: string): any | null {
  const obj = localStorage.getItem(key);
  if (obj === undefined || obj === null) return null;
  else if (typeof obj === "string") return obj;
}

export function isEmptyObject(obj: any): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function urlify(text: string | undefined): string {
  if (text === undefined) return "";
  // Pagesの仕様になる
  const urlRegex = /((https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))/g; // /(https?:\/\/[^\s?!,$]+)/g;
  let result: string = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  // match tel number const : /[0-9]{2,3}[-\s\.]?[0-9]{3,4}[-\s\.]?[0-9]{3,4}/g;
  const telRegex = /[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/g;
  const matched: string[] | null = text.match(telRegex);
  if (matched !== null) {
    for (const key in matched) {
      const match = matched[key];
      result = result.replace(match, `<a href="tel:${match}">${match}</a>`);
    }
  }
  return result;
}