import Vue from "vue";
import router from "@/router";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    deferScriptLoad: true,
    config: {
      id: process.env.NODE_ENV === "production" ? "G-QWCVB23RKF" : "G-GS547TFKXC",
    },
  },
  router,
);
