/**
 * @remarks
 * Vuex module for Data request
 **/
import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import portal from "@vacancorp/portal";
import { ActionTree, MutationTree, GetterTree, Commit, Dispatch } from "vuex";
import { DataState } from "./@types/state-module";
import Api, { FilterParams } from "../api/adapter.api";
import { getLocalStorage, setLocalStorage } from "@/util/utils";
import i18n from "@/i18n";
import { distanceFromLatLng } from "@/mixins/common-functions";
import Vue from "vue";

function getSelectedPlaceIdHash(): string | undefined {
  const selectedPlaceIdHash: string | null | undefined = getLocalStorage("selectedPlaceIdHash");
  return selectedPlaceIdHash === null || selectedPlaceIdHash === undefined ? undefined : selectedPlaceIdHash;
}

export const state: DataState = {
  location: {
    lat: undefined,
    lng: undefined,
  },
  isFirstLoadMap: undefined,
  watchingId: undefined,
  language: i18n.locale,
  placeList: [],
  sortedPlaceList: [],
  restaurantImagesList: [],
  displayVacancyList: [],
  showGPSDenyAlertByTimeout: false,
  hasCurrentLocation: undefined,
  selectedPlaceIdHash: getSelectedPlaceIdHash(),
  selectedPlaceIdHashList: [],
  selectedSameBuilding: undefined,
  selectedSameBuildingPlaceIdHashList: [],
  isFirstSearch: true,
  currentPositionMarker: undefined,
  gMaps: undefined,
  gMapsReady: false,
  restaurantImageNumber: 0,
  vacancyStatusErrorTimes: 0,
  sameBuildingVacancyStatusErrorTimes: 0,
  announcementNumber: 0,
  readAnnouncementNumber: 0,
  selectedTopPlace: {
    areaName: "Default",
    displayText: i18n.t("Nav.area") as string,
    location: { lat: 35.6870985, lng: 139.764569 }, // 大手町
    displayOrder: 1,
  },
  selectedCountry: "jp",
  selectedPrefecture: "",
  genreList: [],
  selectedGenre: undefined,
  selectedGenreCategoryList: [],
  selectedFilterRadioButtons: [],
  mapsZoom: 13,
};

export const mutations: MutationTree<DataState> = {
  setCurrentPositionMarker(state: DataState, payload?: google.maps.Marker) {
    state.currentPositionMarker = payload;
  },
  setIsFirstLoadMap(state: DataState, payload: boolean) {
    state.isFirstLoadMap = payload;
  },
  setShowGPSDenyAlertByTimeout(state: DataState, value: boolean) {
    state.showGPSDenyAlertByTimeout = value;
  },
  setMapsZoom(state: DataState, value: number) {
    state.mapsZoom = value;
  },
  setLanguage(state: DataState, payload: string) {
    state.language = payload;
    setLocalStorage("vacan/maps/language", payload);
  },
  setReadAnnouncementNumber(state: DataState, payload: number) {
    state.readAnnouncementNumber = payload;
  },
  setIsFirstSearch(state: DataState, payload: boolean) {
    state.isFirstSearch = payload;
  },
  setLocation(state: DataState, pos: PortalAdapterApi.Location | undefined) {
    if (pos !== undefined) {
      state.location.lat = pos.lat;
      state.location.lng = pos.lng;
    } else {
      state.location.lat = 0;
      state.location.lng = 0;
    }
    console.log(`--- setLocation ---`);
  },
  setAnnouncementNumber(state: DataState, number: number) {
    state.announcementNumber = number;
  },
  setLocationWatchingId(state: DataState, id: number) {
    state.watchingId = id;
  },
  setSelectedTopPlace: (state: DataState, selectedTopPlace: PortalAdapterApi.TopArea) => {
    state.selectedTopPlace = selectedTopPlace;
  },
  setSelectedCountry: (state: DataState, countryName: string) => {
    state.selectedCountry = countryName;
  },
  setSelectedPrefecture: (state: DataState, name: string) => {
    state.selectedPrefecture = name;
  },
  clearSelectedGenre: (state: DataState) => {
    state.selectedGenre = undefined;
  },
  // selected genre
  setSelectedGenre: (state: DataState, selectedGenre: PortalAdapterApi.PlaceCategoryType) => {
    console.log(`setSelectedGenre: ${selectedGenre}`);
    state.selectedGenre = selectedGenre;
  },
  // genre list from backend
  setGenreList: (state: DataState, genreList: PortalAdapterApi.ResponseGetPlaceCategoryList) => {
    state.genreList = genreList;
  },
  // genre's category
  setSelectedGenreCategoryList: (state: DataState, payload?: string[]) => {
    state.selectedGenreCategoryList = payload === undefined ? [] : payload;
  },
  // quick filter buttons
  setSelectedFilterRadioButtons: (state: DataState, selectedFilterButtons: string[]) => {
    state.selectedFilterRadioButtons = selectedFilterButtons;
  },
  setPlaceList: (state: DataState, placeList: PortalAdapterApi.ResponseGetPublicPlace[]) => {
    state.placeList = placeList;
  },
  setPlaceListIndex: (state: DataState, payload: { index: number; place: PortalAdapterApi.ResponseGetPublicPlace }) => {
    Vue.set(state.placeList, payload.index, payload.place);
  },
  setSortedPlaceList: (state: DataState, placeList: portal.PublicPlaceWithVacancyId[]) => {
    state.sortedPlaceList = placeList;
  },
  setSortedPlaceListIndex: (state: DataState, payload: { index: number; place: portal.PublicPlaceWithVacancyId }) => {
    Vue.set(state.sortedPlaceList, payload.index, payload.place);
  },
  setFacilityVacancyList(state: DataState, displayVacancyList: PortalAdapterApi.ResponseGetMergedDisplayVacancy[]) {
    state.displayVacancyList = displayVacancyList;
  },
  setRestaurantImagesList: (state: DataState, list: PortalAdapterApi.ResponseGetShopImage[]) => {
    state.restaurantImagesList = list;
  },
  hasCurrentLocation(state: DataState, payload: boolean) {
    state.hasCurrentLocation = payload;
  },
  setSelectedPlaceIdHash: (state: DataState, selectedPlaceIdHash: string) => {
    state.selectedPlaceIdHash = selectedPlaceIdHash;
    setLocalStorage("selectedPlaceIdHash", selectedPlaceIdHash);
  },
  setSelectedSameBuilding: (state: DataState, selectedBuilding: PortalAdapterApi.Building) => {
    state.selectedSameBuilding = selectedBuilding;
  },
  setSelectedPlaceIdHashList: (state: DataState, selectedPlaceIdHashList: string[]) => {
    state.selectedPlaceIdHashList = selectedPlaceIdHashList;
  },
  setSelectedSameBuildingPlaceIdHashList: (state: DataState, selectedPlaceIdHashList: string[]) => {
    state.selectedSameBuildingPlaceIdHashList = selectedPlaceIdHashList;
  },
  setRestaurantImageNumber(state: DataState, num: number) {
    state.restaurantImageNumber = num;
  },
  setGMaps(state: DataState, payload: google.maps.Map) {
    state.gMaps = payload;
  },
  setGMapsReady(state: DataState, payload: boolean) {
    state.gMapsReady = payload;
  },
  setVacancyStatusErrorTimes(state: DataState, payload: number) {
    state.vacancyStatusErrorTimes = payload;
  },
  setSameBuildingVacancyStatusErrorTimes(state: DataState, payload: number) {
    state.sameBuildingVacancyStatusErrorTimes = payload;
  },
};

export const actions: ActionTree<DataState, any> = {
  // direct access by areaName
  getLocationByAreaName({ commit }: { commit: Commit }, areaName: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result: PortalAdapterApi.TopArea | undefined = await Api.fetchLocationByAreaName(areaName).catch(() => {
          return Promise.reject(undefined);
        });
        commit("setSelectedTopPlace", result);
        resolve(true);
      } catch {
        reject();
      }
    });
  },
  /** @remarks GPS location */
  checkGeolocation({ commit, dispatch }: { state: DataState; commit: Commit; dispatch: Dispatch }) {
    // check if user allow or deny location permission
    console.log(`--- dispatch checkGeolocation ---`);
    const gpsOption = { timeout: 8000, enableHighAccuracy: true };

    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // success
        (pos) => {
          const location: PortalAdapterApi.Location = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          commit("setLocation", location);
          commit("hasCurrentLocation", true);
          if (state.watchingId === undefined) {
            dispatch("startWatchingGeolocation");
          }
        },
        // error
        (error: any) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              break;
            case error.POSITION_UNAVAILABLE:
              commit("setShowGPSDenyAlertByTimeout", true);
              break;
            case error.TIMEOUT:
              commit("setShowGPSDenyAlertByTimeout", true);
              break;
            case error.UNKNOWN_ERROR:
              commit("setShowGPSDenyAlertByTimeout", true);
              break;
          }
          console.error("checkGeolocation error");
          dispatch("endWatchingGeoLocation");
        },
        gpsOption,
      );
    } else {
      console.error("Browser is not supporting geolocation");
      dispatch("endWatchingGeoLocation");
    }
  },
  startWatchingGeolocation({ state, commit, dispatch }: { state: DataState; commit: Commit; dispatch: Dispatch }) {
    console.log(`--- startWatchingGeolocation ---`);
    if (state.watchingId !== undefined) {
      navigator.geolocation.clearWatch(state.watchingId);
      commit("setLocation", undefined);
      commit("setLocationWatchingId", undefined);
    }
    const options = { timeout: 60000 };
    const id: number = navigator.geolocation.watchPosition(
      (pos) => {
        const location: PortalAdapterApi.Location = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        commit("setLocation", location);
        commit("hasCurrentLocation", true);
      },
      () => {
        console.error("watchGeolocation error");
        dispatch("endWatchingGeoLocation");
      },
      options,
    );
    commit("setLocationWatchingId", id);
  },
  endWatchingGeoLocation({ state, commit }: { state: DataState; commit: Commit }) {
    commit("setLocationWatchingId", undefined);
    commit("hasCurrentLocation", false);
    if (state.watchingId === undefined) return;
    navigator.geolocation.clearWatch(state.watchingId);
  },
  /** @remarks about places */
  // Same building list
  getSameBuildingList({}: { state: DataState; commit: Commit }, buildingIdHash: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const placeList: PortalAdapterApi.ResponseGetPublicPlace[] = await Api.fetchSameBuildingList(
          buildingIdHash,
        ).catch(() => {
          return Promise.reject([]);
        });

        if (placeList !== undefined) {
          resolve(placeList);
        } else {
          reject();
        }
      } catch (e) {
        reject();
      }
    }); // Promise
  },
  // Filter genre category
  fetchGenreCategoryList({}: { state: DataState; commit: Commit }, genreName: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Api.fetchGenreCategoryList(genreName).catch(() => {
          return Promise.reject([]);
        });
        resolve(result);
      } catch (e) {
        reject();
      }
    }); // Promise
  },
  // 店舗リスト
  fetchPlace(
    {}: { state: DataState; commit: Commit },
    payload: {
      lat: number;
      lng: number;
      radius: number;
      countryName: string;
      params: FilterParams;
      areaName?: string;
      mapType?: string;
    },
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const placeList: PortalAdapterApi.ResponseGetPublicPlace[] = await Api.fetchPlaceListByLocation(
          payload.lat,
          payload.lng,
          payload.radius,
          payload.countryName,
          payload.params,
          payload.areaName,
          payload.mapType,
        ).catch(() => {
          return Promise.reject([]);
        });

        if (placeList !== undefined) {
          resolve(placeList);
        } else {
          reject();
        }
      } catch (e) {
        reject();
      }
    }); // Promise
  },
  // 詳細ページ
  getPlaceDetailByPlaceIdHash({}: { state: DataState; commit: Commit }, payload: { placeIdHash: string }) {
    return new Promise(async (resolve, reject) => {
      try {
        let result: PortalAdapterApi.ResponseGetPlaceDetail = await Api.fetchPlaceDetailByPlaceIdHash(
          payload.placeIdHash,
        ).catch(() => Promise.reject({}));

        if (result.placeIdHash !== undefined) {
          resolve(result);
        } else {
          reject({});
        }
      } catch (e) {
        reject();
      }
    }); //Promise
  },
  // 店舗の画像
  getPlaceImagesByPlaceIdHash(
    {}: { state: DataState; commit: Commit },
    payload: { page: number; placeIdHash: string },
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const imageList: PortalAdapterApi.ResponseGetShopImage[] = await Api.fetchPlaceImagesByPlaceIdHash(
          payload.placeIdHash,
          payload.page,
        ).catch(() => {
          return Promise.reject([]);
        });

        if (imageList !== undefined) {
          resolve(imageList);
        } else {
          reject();
        }
      } catch (e) {
        reject();
      }
    });
  },
  // 店舗のcoupon
  getCouponListByPlaceIdHash({}: { state: DataState; commit: Commit }, payload: { placeIdHash: string }) {
    return new Promise(async (resolve, reject) => {
      try {
        const result: PortalAdapterApi.ResponseGetPublicCouponForPortal[] = await Api.fetchCouponListByPlaceIdHash(
          payload.placeIdHash,
        ).catch(() => {
          return Promise.reject([]);
        });
        if (result !== undefined) resolve(result);
        else reject([]);
      } catch (e) {
        reject();
      }
    });
  },
  // お知らせ
  getAnnouncementList() {
    return new Promise(async (resolve, reject) => {
      try {
        const result: PortalAdapterApi.ResponseGetNotice[] = await Api.fetchAnnouncementList().catch(() => {
          return Promise.reject([]);
        });
        if (result !== undefined) resolve(result);
        else reject([]);
      } catch (e) {
        reject();
      }
    });
  },
  getAnnouncementNumber() {
    return new Promise(async (resolve, reject) => {
      try {
        const result: number = await Api.fetchAnnouncementNumber().catch(() => {
          return Promise.reject([]);
        });
        if (!isNaN(result)) resolve(result);
        else reject(-1);
      } catch (e) {
        reject();
      }
    });
  },
  /** @remarks get vacancy list of places and their facilities every 10 seconds */
  getPlaceFacilityVacancyList({}: { commit: Commit; state: DataState; dispatch: Dispatch }, placeIdHashList: string[]) {
    return new Promise(async (resolve, reject) => {
      const displayVacancyList: PortalAdapterApi.ResponseGetMergedDisplayVacancy[] | undefined =
        await Api.fetchFacilityVacancyListByPlaceIdHashList(placeIdHashList).catch(() => {
          console.error(`catch getPlaceFacilityVacancyList in vuex`);
          return undefined;
        });

      if (displayVacancyList !== undefined) {
        resolve(displayVacancyList);
      } else {
        reject([]);
      }
      // ignore reject here because of in a loop
    });
  },
};

export const getters: GetterTree<DataState, any> = {
  isNotNormalMap: (state: DataState) => {
    return ["polling-place", "evacuation-center", "disaster-prevention"].includes(state.selectedGenre ?? "");
  },
  getSelectedCountry: (state: DataState) => {
    return state.selectedCountry;
  },
  /** @remarks Control Google Maps */
  metersPerPixal(state: DataState) {
    return function (zoom: number): number {
      if (state.gMaps === undefined) return 0;
      const center: google.maps.LatLng = state.gMaps?.getCenter();
      return (156543.03392 * Math.cos((center.lat() * Math.PI) / 180)) / Math.pow(2, zoom);
    };
  },
  radiusFromCenter() {
    return function (payload: { metersPerPixal: number; windowWidth: number }): number {
      return (payload.windowWidth / 2) * payload.metersPerPixal;
    };
  },
  isPlaceListNoData(state: DataState): boolean {
    return state.placeList.length === 0;
  },
  getLanguageWithoutCountry(state: DataState): string {
    return state.language.substring(0, 2);
  },
  // support language: zh-cn, ja-jp, ko-kr, zh-tw, en-us
  getLanguageWithCountryOnly(state: DataState): string {
    return state.language.substring(3, 5).toLowerCase();
  },
  getDistanceFromCurrentLocation(state: DataState) {
    return function (targetLocation: PortalAdapterApi.Location): string {
      if (state.location.lat === undefined) return "";
      const currentLocation = state.location as PortalAdapterApi.Location;
      // km
      const distance: number = distanceFromLatLng(currentLocation, targetLocation);

      if (distance === -1) return "";

      let displayDistance: string = `${distance}`;
      return displayDistance;
    };
  },
  compareDistanceWithCurrentLocation(state: DataState) {
    return function (location: { lat: number; lng: number }): number | undefined {
      if (google === undefined) return undefined;
      const currentLocationLatLng: google.maps.LatLng = new google.maps.LatLng(
        state.location.lat as number,
        state.location.lng as number,
      );

      if (currentLocationLatLng.lat == null || currentLocationLatLng.lng == null) {
        return 0;
      }

      const targetLatLng: google.maps.LatLng = new google.maps.LatLng(location.lat, location.lng);
      const distance: number = google.maps.geometry.spherical.computeDistanceBetween(
        targetLatLng,
        currentLocationLatLng,
      );

      return distance;
    };
  },
  compareDistanceWithSelectedTopPlaceLocation(state: DataState) {
    return function (location: { lat: number; lng: number }): number | undefined {
      if (state?.selectedTopPlace?.location === undefined || google === undefined) return;

      const topPlaceLocationLatLng: google.maps.LatLng = new google.maps.LatLng(
        state?.selectedTopPlace?.location.lat as number,
        state?.selectedTopPlace?.location.lng as number,
      );

      if (topPlaceLocationLatLng.lat == null || topPlaceLocationLatLng.lng == null) {
        return 0;
      }

      const targetLatLng: google.maps.LatLng = new google.maps.LatLng(location.lat, location.lng);
      const distance: number = google.maps.geometry.spherical.computeDistanceBetween(
        targetLatLng,
        topPlaceLocationLatLng,
      );

      return distance;
    };
  },
  getLocationOfPlace(state: DataState) {
    return function (placeIdHash: string): { lat: number; lng: number } | undefined {
      const selectedPlace: PortalAdapterApi.ResponseGetPublicPlace | undefined = state.sortedPlaceList.find(
        (item: portal.PublicPlaceWithVacancyId) => item.placeIdHash === placeIdHash,
      );

      return selectedPlace !== undefined && selectedPlace.location !== undefined ? selectedPlace.location : undefined;
    };
  },
  getVacancyObjectByPlaceIdHash(state: DataState) {
    return (placeIdHash: string): PortalAdapterApi.ResponseGetMergedDisplayVacancy | undefined => {
      const result: PortalAdapterApi.ResponseGetMergedDisplayVacancy | undefined = state.displayVacancyList.find(
        (item: PortalAdapterApi.ResponseGetMergedDisplayVacancy) => placeIdHash === item.placeIdHash,
      );
      return result !== undefined ? result : undefined;
    };
  },
  getPlaceNameByPlaceIdHashFromSortedList(state: DataState) {
    return (placeIdHash: string): string | undefined => {
      const target: portal.PublicPlaceWithVacancyId | undefined = state.sortedPlaceList.find(
        (place) => place.placeIdHash === placeIdHash,
      );
      return target?.name;
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
