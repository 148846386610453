export function iOS(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1 || ua.indexOf("ipod") > -1;
}

export function iOSVersion(): number {
  const ua: string = window.navigator.userAgent.toLowerCase();
  const result: RegExpMatchArray | null = ua.match(/OS (\d)?\d_\d(_\d)?/i);
  if (result === null) return -1;
  const strVersion: string = result[0]; // os 14_1_x
  const splits: string[] = strVersion.replace("os ", "").split("_");
  if (splits.length === 0) return -1;
  const mainVersion: string = splits[0];
  const minorVersion: string = splits[1] || "";
  return minorVersion.length === 0 ? Number(mainVersion) : Number(`${mainVersion}.${minorVersion}`);
}
export function isBelowIOS14(): boolean {
  return iOSVersion() !== -1 && iOSVersion() < 15;
}

export function iPadOS(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    ua.indexOf("ipad") > -1 ||
    ((ua.indexOf("macintosh") > -1 || ua.indexOf("macintel") > -1) && "ontouchend" in document)
  );
}

export function isPC(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    (/macintosh/.test(ua) && !iPadOS()) ||
    (/windows/.test(ua) && !("ontouchend" in document)) ||
    (/linux_x86_64/.test(ua) && !("ontouchend" in document)) ||
    (/linux_i686/.test(ua) && !("ontouchend" in document)) ||
    (/linux i686/.test(ua) && !("ontouchend" in document)) ||
    (/linux x86_64/.test(ua) && !("ontouchend" in document))
  );
}

export function macOS(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  return /macintosh/.test(ua) && !("ontouchend" in document);
}

export function getWidthOfShortScreenSide(): number {
  return Math.min(window.innerWidth, window.innerHeight);
}

export function isTablet(): boolean {
  return !isPC() && getWidthOfShortScreenSide() >= 590;
}

export function isMobile(): boolean {
  return !isPC() && getWidthOfShortScreenSide() < 590;
}

export default {
  iOS,
  iPadOS,
  isPC,
  getWidthOfShortScreenSide,
  isTablet,
  isMobile,
  isBelowIOS14,
  iOSVersion,
  macOS,
};
