import Router from "vue-router";
import i18n from "@/i18n";

/** @remarks mobile components */
const TopView = () => import(/* webpackChunkName: "top", webpackPrefetch: true*/ "@/views/top-view.vue");
const MapsView = () => import(/* webpackChunkName: "map"*/ "@/views/maps-view.vue");
// place
const DetailViewIndex = () => import(/* webpackChunkName: "place"*/ "@/views/detail-view-index.vue");
const RestaurantImagesView = () => import(/* webpackChunkName: "place"*/ "@/views/place-images-view.vue");
const CouponView = () => import(/* webpackChunkName: "place"*/ "@/views/coupon-view.vue");
const CouponCheckInView = () => import(/* webpackChunkName: "place"*/ "@/views/coupon-check-in-success.vue");
const SameBuildingView = () => import(/* webpackChunkName: "building"*/ "@/views/same-building-view.vue");
// menu
const AnnouncementView = () => import(/* webpackChunkName: "menu"*/ "@/views/announcement-view.vue");
const TutorialView = () => import(/* webpackChunkName: "menu"*/ "@/views/tutorial-view.vue");
const FaqView = () => import(/* webpackChunkName: "menu"*/ "@/views/faq-view.vue");

const FavoriteView = () => import(/* webpackChunkName: "favorite"*/ "@/views/favorite-list.vue");
// login
const LoginView = () => import(/* webpackChunkName: "login"*/ "@/views/login-view.vue");
const LoginC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/login.vue");
const signupC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/signup.vue");
const forgotC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/forgot-password.vue");
const resetC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/reset.vue");

/** WCAG */
const WcagSelectionView = () => import(/* webpackChunkName: "wcag"*/ "@/views/wcag-selection-view.vue");

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // wcag selection
    {
      path: "/:lang?/wcag-selection",
      components: {
        default: WcagSelectionView,
      },
      meta: {
        title: i18n.t("ColorContrast.header"),
      },
    },
    {
      path: "/:lang?/login",
      components: {
        default: LoginView,
      },
      meta: {
        title: `${i18n.t("SEO.loginTitle")} - VACAN Maps（バカン）`,
      },
      children: [
        {
          path: "/:lang?/reset/:mode",
          components: {
            default: resetC,
          },
        },
        {
          path: "",
          components: {
            default: LoginC,
          },
        },
        {
          path: "/:lang?/signup",
          components: {
            default: signupC,
          },
        },
        {
          path: "/:lang?/forgot-password",
          components: {
            default: forgotC,
          },
        },
      ],
    },
    {
      path: "/:lang?/tutorial",
      components: {
        default: TutorialView,
        tablet: TutorialView,
        desktop: TutorialView,
      },
      meta: {
        title: `${i18n.t("SEO.tutorialTitle")} | [VACAN Maps（バカン）]`,
      },
    },
    // 同じ施設
    {
      path: "/:lang?/building/:buildingIdHash",
      name: "SameBuildingView",
      components: {
        default: SameBuildingView,
      },
      meta: {
        title: `${i18n.t("CardView.sameBuilding")} | VACAN Maps（バカン）`,
      },
    },
    // 詳細ページ
    {
      path: "/:lang?/place/:placeIdHash",
      components: {
        default: DetailViewIndex,
      },
      children: [
        {
          path: "images",
          components: {
            default: RestaurantImagesView,
          },
          meta: {
            title: `${i18n.t("ImagePage.title")} [VACAN Maps（バカン）]`,
          },
        },
        {
          path: "coupons",
          components: {
            default: CouponView,
          },
          meta: {
            title: `${i18n.t("Coupon.title")} [VACAN Maps（バカン）]`,
          },
          children: [
            {
              path: "checked-in",
              components: {
                default: CouponCheckInView,
              },
              meta: {
                title: `${i18n.t("Coupon.title")} [VACAN Maps（バカン）]`,
              },
            },
          ],
        },
      ],
    },
    // new maps
    {
      path: "/:lang?/map",
      components: {
        default: MapsView,
      },
      meta: {
        title: `VACAN Maps-バカン-`,
      },
      children: [
        // maps search
        {
          path: "/:lang?/map/:latlng",
        },
        // direct access 2
        {
          path: "/:lang?/area/:areaName/:genre/:mapZoom",
        },
        {
          path: "/:lang?/area/:areaName/:genre/:mapZoom/:genreCategoryList",
        },
        // direct access
        {
          path: "/:lang?/area/:areaName",
        },
        {
          path: "/:lang?/favorite",
          meta: {
            requireLogin: ["login"],
            title: `${i18n.t("SEO.favoriteTitle")} VACAN Maps（バカン）`,
          },
          components: {
            default: FavoriteView,
          },
        },
        {
          path: "/:lang?/faq",
          components: {
            default: FaqView,
          },
          meta: {
            title: `${i18n.t("faq.h1Title")} [VACAN Maps（バカン）]`,
          },
        },
        {
          path: "/:lang?/menu-tutorial",
          components: {
            default: TutorialView,
          },
          meta: {
            title: `${i18n.t("SEO.tutorialTitle")} | [VACAN Maps（バカン）]`,
          },
        },
        {
          path: "/:lang?/announcement",
          components: {
            default: AnnouncementView,
          },
          meta: {
            title: `${i18n.t("AnnouncementView.title")} [VACAN Maps（バカン）]`,
          },
        },
      ],
    },
    // new top view
    {
      path: "/:lang?",
      components: {
        default: TopView,
      },
      meta: {
        title: `VACAN Maps-バカン-`,
      },
    },
  ],
});
