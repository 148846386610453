import Vue from "vue";
// monitoring
import "./plugins/sentry";
// root component
import App from "@/App.vue";
// plugins
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import "@/plugins/vue-gtag";
// lazy-load-image
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  lazyComponent: true,
});
// kick off the polyfill!
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
// do not show dev tool tip
Vue.config.productionTip = false;
// filter mixin plugin
import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
