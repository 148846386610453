// Entry to Vuex modules
import Vue from "vue";
import Vuex from "vuex";
import UiModule from "./ui-module";
import DataModule from "./data-module";
import AuthModule from "./auth-module";

import { UIState, DataState, AuthState } from "./@types/state-module";

export interface State {
  ui: UIState;
  data: DataState;
  auth: AuthState;
}

Vue.use(Vuex);

export default new Vuex.Store<any>({
  modules: {
    auth: AuthModule,
    ui: UiModule,
    data: DataModule,
  },
});
