import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import { IAuth, AccessToken } from "../@types/auth";

export class Auth implements IAuth {
  accessToken?: AccessToken;
  isResponsePostLogin(
    tokens: unknown
  ): tokens is PortalAdapterApi.ResponsePostLogin {
    return (
      "accessToken" in (tokens as PortalAdapterApi.ResponsePostLogin) &&
      typeof (tokens as PortalAdapterApi.ResponsePostLogin).accessToken ===
        "string"
    );
  }
  parseJwt(token: string): Object {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}
