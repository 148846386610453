
import { mapGetters, mapMutations, mapState } from "vuex";
import New3MapTypeBtnMixin from "@/mixins/new-3-map-type-btn";
import VueTypedMixin from "vue-typed-mixins";
import { setCookie, getCookie } from "@/util/cookie";
import { isWcagMode } from "@/util/wcag";

export default VueTypedMixin(New3MapTypeBtnMixin).extend({
  name: "app",
  mixins: [New3MapTypeBtnMixin],
  components: {
    LandscapeAlert: () => import(/* webpackChunkName: "landscape-alert" */ "@/components/landscape-alert.vue"),
    FavoritePrompt: () => import("@/components/favorite-prompt.vue"),
    Model20210519: () => import("@/components/common/model-20210519.vue"),
    TopViewHinanjoSearchContent: () => import("@/components/top-view/top-view-hinanjo-search-content.vue"),
    NudgeMessage: () => import("@/components/common/nudge-message.vue"),
  },

  data: () => ({
    isNormalMode: true,
    isContrastNudgeShown: true,
  }),

  computed: {
    ...mapState("ui", ["favoritePrompt", "showSearchAlertUI"]),
    ...mapState("data", ["hasCurrentLocation"]),
    ...mapGetters("ui", ["isMobile"]),
  },

  watch: {
    gpsButtonStatus(newVal?: boolean) {
      console.log(`watch gpsButtonStatus at app.vue: ${newVal}`);
      this.modalButtons[0].status = this.gpsButtonStatus;
    },
    hasCurrentLocation(newVal?: boolean) {
      console.log(`watch CurrentLocation at app.vue: ${newVal}`);
      if (newVal) {
        this.gpsButtonStatus = "normal";
      } else {
        this.gpsButtonStatus = "error";
      }
    },
  },

  created() {
    this.isNormalMode = !isWcagMode();
    this.isContrastNudgeShown = getCookie("color-mode") === undefined;
    // to set because most of previous codes using it
    this.$store.commit("data/setLanguage", this.$i18n.locale);
    this.resetPersistVuex();
  },

  methods: {
    ...mapMutations("ui", ["setShowSearchAlertUI"]),
    /** @remarks first visit */
    resetPersistVuex() {
      this.$store.commit("ui/setAlertView", {
        status: false,
        msg: "",
        btnTitle: "",
      });
      this.$store.commit("ui/isFetchingError", false);
    },

    closeContrastNudge() {
      this.isContrastNudgeShown = false;
      setCookie("color-mode", "normal", 400);
    },

    clickContrastNudge() {
      this.isContrastNudgeShown = false;
      this.$router.push("/wcag-selection");
    },
  },
});
