import Router from "vue-router";
import i18n from "@/i18n";

/** @remarks desktop components */
const TopViewDesktop = () => import(/* webpackChunkName: "top" */ "@/views/top-view.vue");
const MapsViewDesktop = () => import(/* webpackChunkName: "map" */ "@/views-desktop/maps-view.vue");
// placeconst
const DetailViewIndex = () => import(/* webpackChunkName: "place"*/ "@/views/detail-view-index.vue");
const RestaurantImagesViewDesktop = () => import(/* webpackChunkName: "place" */ "@/views/place-images-view.vue");
const CouponViewDesktop = () => import(/* webpackChunkName: "place"*/ "@/views-desktop/coupon-view.vue");
const SameBuildingViewDesktop = () => import(/* webpackChunkName: "building" */ "@/views/same-building-view.vue");
// menu
const TutorialView = () => import(/* webpackChunkName: "menu"*/ "@/views/tutorial-view.vue");
const AnnouncementViewDesktop = () => import(/* webpackChunkName: "menu"*/ "@/views/announcement-view.vue");
const FaqViewDesktop = () => import(/* webpackChunkName: "menu"*/ "@/views/faq-view.vue");

const FavoriteViewDesktop = () => import(/* webpackChunkName: "favorite"*/ "@/views/favorite-list.vue");
// login
const LoginView = () => import(/* webpackChunkName: "login"*/ "@/views/login-view.vue");
const LoginC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/login.vue");
const signupC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/signup.vue");
const forgotC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/forgot-password.vue");
const resetC = () => import(/* webpackChunkName: "login"*/ "@/components/login-views/reset.vue");

/** WCAG */
const WcagSelectionView = () => import(/* webpackChunkName: "wcag"*/ "@/views/wcag-selection-view.vue");

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // wcag selection
    {
      path: "/:lang?/wcag-selection",
      components: {
        default: WcagSelectionView,
      },
      meta: {
        title: i18n.t("ColorContrast.header"),
      },
    },
    {
      path: "/:lang?/login",
      components: {
        default: LoginView,
      },
      meta: {
        title: `${i18n.t("SEO.loginTitle")} - VACAN Maps（バカン）`,
      },
      children: [
        {
          path: "/:lang?/reset/:mode",
          components: {
            default: resetC,
          },
        },
        {
          path: "",
          components: {
            default: LoginC,
          },
        },
        {
          path: "/:lang?/signup",
          components: {
            default: signupC,
          },
        },
        {
          path: "/:lang?/forgot-password",
          components: {
            default: forgotC,
          },
        },
      ],
    },
    {
      path: "/:lang?/tutorial",
      components: {
        default: TutorialView,
        tablet: TutorialView,
        desktop: TutorialView,
      },
      meta: {
        title: `${i18n.t("SEO.tutorialTitle")} | [VACAN Maps（バカン）]`,
      },
    },
    // maps
    {
      path: "/:lang?/map",
      components: {
        default: MapsViewDesktop,
      },
      children: [
        // maps search
        {
          path: "/:lang?/map/:latlng",
        },
        // area search
        {
          path: "/:lang?/area/:areaName/:genre/:mapZoom",
        },
        {
          path: "/:lang?/area/:areaName/:genre/:mapZoom/:genreCategoryList",
        },
        {
          path: "/:lang?/area/:areaName",
        },

        //お気入れ
        {
          path: "/:lang?/favorite",
          meta: {
            requireLogin: ["login"],
            title: `${i18n.t("SEO.favoriteTitle")} VACAN Maps（バカン）`,
          },
          components: {
            default: FavoriteViewDesktop,
          },
        },
        //faq
        {
          path: "/:lang?/faq",
          components: {
            default: FaqViewDesktop,
          },
          meta: {
            title: `${i18n.t("faq.h1Title")} [VACAN Maps（バカン）]`,
          },
        },
        //メニュー上のtutorial
        {
          path: "/:lang?/menu-tutorial",
          components: {
            default: TutorialView,
          },
          meta: {
            title: `${i18n.t("SEO.tutorialTitle")} | [VACAN Maps（バカン）]`,
          },
        },
        //お知らせ
        {
          path: "/:lang?/announcement",
          components: {
            default: AnnouncementViewDesktop,
          },
          meta: {
            title: `${i18n.t("AnnouncementView.title")} [VACAN Maps（バカン）]`,
          },
        },
      ],
    },
    //詳細ページ
    {
      path: "/:lang?/place/:placeIdHash",
      components: {
        default: DetailViewIndex,
      },
      children: [
        {
          path: "images",
          components: {
            default: RestaurantImagesViewDesktop,
          },
          meta: {
            title: `${i18n.t("ImagePage.title")} [VACAN Maps（バカン）]`,
          },
        },
        {
          path: "coupons",
          components: {
            default: CouponViewDesktop,
          },
          meta: {
            title: `${i18n.t("Coupon.title")} [VACAN Maps（バカン）]`,
          },
        },
      ],
    },
    //同じ施設
    {
      // :lang? is needed for child with property 'name'
      path: "/:lang?/building/:buildingIdHash",
      name: "SameBuildingView",
      components: {
        default: SameBuildingViewDesktop,
      },
      meta: {
        title: `${i18n.t("CardView.sameBuilding")} | VACAN Maps（バカン）`,
      },
    },
    // new top view
    {
      path: "/:lang?",
      components: {
        default: TopViewDesktop,
      },
      meta: {
        title: `VACAN Maps-バカン-`,
      },
    },
  ],
});
