import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { fetchHasPollPlaces } from "@/api/adapter.api";

export default Vue.extend({
  name: "mixin-new-3-map-type-btn",
  data() {
    return {
      gpsButtonStatus: "normal",
      isPollBtnActive: false,
      // popup modal of どの方法で探しますか
      modalButtons: [
        {
          title: {
            fallbackLanguage: "ja_JP",
            languageList: {
              en_US: "Current Location",
              ja_JP: "現在地から探す",
              zh_CN: "看看附近有什么",
              zh_TW: "搜尋附近",
              ko_KR: "현재 위치에서 검색",
            },
          },
          type: "primary",
          status: "normal",
          callBack: "searchCurrentBtnClicked",
        },
        {
          title: {
            fallbackLanguage: "ja_JP",
            languageList: {
              en_US: "Search by Area",
              ja_JP: "エリアから探す",
              zh_CN: "根据地区查找",
              zh_TW: "依區域搜尋",
              ko_KR: "지역에서 검색",
            },
          },
          type: "outlined",
          status: "normal",
          callBack: "searchAreaBtnClicked",
        },
      ],
    };
  },
  computed: {
    ...mapState("data", ["hasCurrentLocation", "selectedGenre"]),
    ...mapState("data", {
      currentLocation: "location",
    }),
    ...mapState("ui", ["showSearchAlertUI", "whichBtnClickedOnSearchAlertUI"]),
    showPollBtnTitle(): string {
      return this.isPollBtnActive
        ? (this.$i18n.t("Top.searchPoll") as string)
        : (this.$i18n.t("Top.searchPoll2") as string);
    },
    showHinan(): string {
      return require("@/assets/images/top/top-hinan.svg");
    },
    showPoll(): string {
      return require("@/assets/images/top/top-polling.svg");
    },
    showNormalMap(): string {
      return require("@/assets/images/top/top-normal-map.svg");
    },
  },
  methods: {
    ...mapMutations("data", ["setLocation", "setSelectedGenre", "clearSelectedGenre", "setSelectedTopPlace"]),
    ...mapMutations("ui", [
      "setShowSearchAlertUI",
      "showMenuPanel",
      "setShowTopAreaList",
      "setWhichBtnClickedOnSearchAlertUI",
    ]),
    /**
     * @remarks
     * three actions onto three map types
     */
    searchCurrentBtnClicked() {
      // GA, GTM tracking
      this.$gtag.event("click", {
        event_category: "Top menu view",
        event_label: "Search current button",
        value: 1,
      });
      this.setWhichBtnClickedOnSearchAlertUI("all");
      // show popup
      if (this.hasCurrentLocation === false) {
        this.searchAreaBtnClicked();
      } else {
        this.setShowSearchAlertUI(true);
      }
    },
    searchHinanjoBtnClicked() {
      // GA, GTM tracking
      this.$gtag.event("click", {
        event_category: "Top menu view",
        event_label: "Search evacuation-center button",
        value: 1,
      });
      this.setWhichBtnClickedOnSearchAlertUI("evacuation-center");
      // show popup
      if (this.hasCurrentLocation === false) {
        this.searchAreaBtnClicked();
      } else {
        this.setShowSearchAlertUI(true);
      }
    },
    searchPollBtnClicked() {
      // GA, GTM tracking
      this.$gtag.event("click", {
        event_category: "Top menu view",
        event_label: "Search polls button",
        value: 1,
      });
      this.setWhichBtnClickedOnSearchAlertUI("polling-place");
      this.searchAreaBtnClicked();
    },
    /**
     * @remarks
     * if clicked on top page, then go to /map
     * else if clicked on map view, then just open area panel
     */
    searchAreaBtnClicked() {
      setTimeout(() => {
        // on map view's menu, do not change URL route otherwise it will fetch place again
        if (this.$route.path.includes("/map")) {
          this.setShowTopAreaList(true);
        } else {
          // on top view
          this.$router.push(`/map?areaMenu=1`).catch(() => {
            console.warn("router cannot be pushed");
          });
        }
      }, 300);
    },
    checkGPSLocation() {
      this.$store.dispatch("data/checkGeolocation");
    },
    searchWithCurrentLocation() {
      this.setSelectedTopPlace({
        areaName: "current-place",
        displayText: this.$i18n.t("Nav.gpsBtn.title") as string,
        location: this.currentLocation,
        displayOrder: 1,
      });
      setTimeout(() => {
        this.$router
          .push(`/map/${this.currentLocation.lat},${this.currentLocation.lng}?genre=${this.selectedGenre}`)
          .catch(() => {
            console.warn("router cannot be pushed");
          });
      }, 200);
    },
    // modal popup for ShowSearchAlertUI
    modalBtnClicked(callBack: string) {
      console.log(`modalBtnClicked: ${callBack}, ${this.whichBtnClickedOnSearchAlertUI}`);
      switch (callBack) {
        case "searchCurrentBtnClicked": {
          this.setSelectedGenre(this.whichBtnClickedOnSearchAlertUI);
          if (this.gpsButtonStatus === "error") return;
          if (this.hasCurrentLocation === undefined) {
            this.gpsButtonStatus = "loading";
          } else if (this.hasCurrentLocation === false) {
            this.gpsButtonStatus = "error";
          } else {
            if (this.currentLocation.lat !== undefined) {
              this.setShowSearchAlertUI(false);
              this.showMenuPanel(false);
              this.searchWithCurrentLocation();
              return;
            }
          }
          this.checkGPSLocation();
          break;
        }
        case "searchAreaBtnClicked": {
          this.searchAreaBtnClicked();
          this.closeSearchAlertUI();
          break;
        }
        default: {
          break;
        }
      }
    },
    closeSearchAlertUI() {
      this.setShowSearchAlertUI(false);
    },
    setGpsButtonStatus(payload: string) {
      this.gpsButtonStatus = payload;
    },
    setIsPollBtnActive(payload: boolean) {
      this.isPollBtnActive = payload;
    },
    async checkPollPlaces(): Promise<boolean> {
      let active: boolean = await fetchHasPollPlaces().catch(() => {
        return false;
      });
      return active;
    },
  },
});
