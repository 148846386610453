import { getCookie } from "./cookie";

/**
 * @cookies color-mode = "normal" | "wcag"
 */
export function isWcagMode(): boolean {
  return getCookie("color-mode") === "wcag";
}

export function wcagParam() {
  const isWcag = isWcagMode();
  return { wcag: isWcag ? "on" : undefined };
}
