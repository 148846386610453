import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import router from "@/router";
import { renewSession } from "./adapter.api";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ADAPTER_API_BASE_URL,
  timeout: 20 * 1000,
});

const authorizationItem = (): string => {
  return `Bearer ${localStorage.getItem("session/tokens/accessToken") || ""}`;
};

const haveSession = (): boolean => {
  const token: string | null = localStorage.getItem("session/tokens/accessToken");
  return token !== null && token.length > 0;
};

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (haveSession()) {
      config.headers.common["Authorization"] = authorizationItem();
    }
    // for ie11
    if (config.method === "get") {
      const timestamp: number = new Date().getTime();
      config.params = { ...config.params, timestamp };
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
  },
);

// Check accesstoken validity if not try to refresh tokens
http.interceptors.response.use(
  async (response: AxiosResponse): Promise<AxiosResponse> => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfig = error.config;
    //FIX: take off when Cognito issue gets fixed
    if (originalRequest.url?.includes("/signout")) {
      return Promise.resolve(error.response);
    }
    // Issue to multiple devices login
    //FIX: take off when Cognito issues get fixed
    else if (error.response !== undefined && error.response.status === 401) {
      const loginResult: PortalAdapterApi.ResponsePostLogin | undefined = await renewSession().catch(() => undefined);

      if (loginResult !== undefined) {
        // deal with session 500 issue
        // for preventing showing login page as checking reservation status on map view
        if (!haveSession()) {
          return Promise.reject(error);
        }
        localStorage.setItem("session/tokens/accessToken", loginResult.accessToken);
        localStorage.setItem("session/tokens/refreshToken", loginResult.refreshToken);
        originalRequest.headers["Authorization"] = `Bearer ${loginResult.accessToken}`;
        return http.request(originalRequest);
      }
      return Promise.reject(error);
    }
    // FIX: take off when Cognito issues get fixed
    // special handling to not to show login page when getting 401
    // the expected response.code should be 500
    else if (originalRequest.url?.includes("/reset") && error.response !== undefined && error.response.status === 401) {
      return Promise.resolve(error.response);
    } else if (error.response !== undefined && error.response.status === 401) {
      if (!haveSession()) {
        router.push("/login");
        return Promise.reject(error);
      }

      const loginResult: PortalAdapterApi.ResponsePostLogin | undefined = await renewSession().catch(() => undefined);
      if (loginResult === undefined) {
        router.push("/login");
        return Promise.reject(error);
      }

      localStorage.setItem("session/tokens/accessToken", loginResult.accessToken);
      localStorage.setItem("session/tokens/refreshToken", loginResult.refreshToken);
      originalRequest.headers["Authorization"] = `Bearer ${loginResult.accessToken}`;
      return http.request(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default http;
