/**
 * @remarks
 * Vuex module for UI
 **/
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { UIState } from "./@types/state-module";
import { isTablet, isPC, isMobile, iOS } from "@/util/mobile";
/** @remarks
 * The state data */
export const state: UIState = {
  vacancyErrorBannerShown: false,
  isAreaViewModal: false,
  deviceOrientation: {
    orientation: "portraits",
    width: 0,
    height: 0,
  },
  showDenyGpsAlertView: false,
  viewState: "map",
  showFacility: false,
  showRestaurant: false,
  menuPanelShown: false,
  announcementViewShown: false,
  showReloadButton: false,
  isDetailViewScrollTop: false,
  detailViewPosY: -1,
  showGPSAlertWindow: false,
  stopElementScrolling: false,
  increaseZIndex: 2,
  resizeGMaps: false,
  isFetchingError: false,
  isFirstTimeUsing: true,
  IMGPAGENUMBER: 5,
  navigationClicked: false,
  noNetworkImage: "",
  alertView: {
    status: false,
    msg: "",
    btnTitle: "",
  },
  alertBar: {
    status: false,
    title: "",
    subtitle: "",
  },
  selectedCardHasSameBuilding: false,
  favoritePrompt: false,
  showLoadingCard: false,
  showSearchAlertUI: false,
  whichBtnClickedOnSearchAlertUI: undefined,
  showTopAreaList: false,
  stringCardHeaderShown: undefined,
  bottomSectionOffsetTop: undefined,
};

/** @remarks
 * Use mutations to modify state data in sync way */
export const mutations: MutationTree<UIState> = {
  setStringCardHeaderShown(state: UIState, payload: string) {
    state.stringCardHeaderShown = payload;
    console.warn(`setStringCardHeaderShown: ${payload}`);
  },
  setWhichBtnClickedOnSearchAlertUI(state: UIState, payload: string) {
    state.whichBtnClickedOnSearchAlertUI = payload;
    console.log(`setWhichBtnClickedOnSearchAlertUI vuex: ${state.whichBtnClickedOnSearchAlertUI}`);
  },
  setShowTopAreaList(state: UIState, payload: boolean) {
    state.showTopAreaList = payload;
  },
  setShowSearchAlertUI(state: UIState, payload: boolean) {
    state.showSearchAlertUI = payload;
  },
  setVacancyErrorBannerShown(state: UIState, payload: boolean) {
    state.vacancyErrorBannerShown = payload;
  },
  setIsAreaViewModal(state: UIState, payload: boolean) {
    state.isAreaViewModal = payload;
  },
  setShowDenyGpsAlertView(state: UIState, payload: boolean) {
    state.showDenyGpsAlertView = payload;
  },
  setShowLoadingCard(state: UIState, payload: boolean) {
    state.showLoadingCard = payload;
  },
  setFavoritePrompt(state: UIState, payload: boolean) {
    state.favoritePrompt = payload;
  },
  setDeviceOrientation(
    state: UIState,
    payload: {
      orientation: "portraits" | "landscape";
      width: number;
      height: number;
    },
  ) {
    state.deviceOrientation = payload;
  },
  setViewState(state: UIState, payload: "map" | "list") {
    state.viewState = payload;
  },
  setNoNetworkImage(state: UIState, payload: string) {
    state.noNetworkImage = payload;
  },
  showMenuPanel(state: UIState, payload: boolean) {
    state.menuPanelShown = payload;
  },
  setSelectedCardHasSameBuilding(state: UIState, payload: boolean) {
    state.selectedCardHasSameBuilding = payload;
  },
  setNavigationClicked(state: UIState, payload: boolean) {
    state.navigationClicked = payload;
  },
  isFirstTimeUsing(state: UIState, payload: boolean) {
    state.isFirstTimeUsing = payload;
  },
  setAnnouncementViewShown(state: UIState, payload: boolean) {
    state.announcementViewShown = payload;
  },
  setShowFacility(state: UIState, payload: boolean) {
    state.showFacility = payload;
  },
  setShowRestaurant(state: UIState, payload: boolean) {
    state.showRestaurant = payload;
  },
  isDetailViewScrollTop(state: UIState, payload: boolean) {
    state.isDetailViewScrollTop = payload;
  },
  detailViewPosY(state: UIState, pos: number) {
    state.detailViewPosY = pos;
  },
  stopElementScrolling(state: UIState, payload: boolean) {
    state.stopElementScrolling = payload;
  },
  resizeGMap(state: UIState, payload: boolean) {
    state.resizeGMaps = payload;
  },
  setZIndex(state: UIState, payload: number) {
    state.increaseZIndex = payload;
  },
  setShowReloadButton: (state: UIState, val: boolean) => {
    state.showReloadButton = val;
  },
  isFetchingError: (state: UIState, val: boolean) => {
    state.isFetchingError = val;
  },
  setAlertView: (
    state: UIState,
    payload: {
      status: boolean;
      msg: string;
      btnTitle: string;
    },
  ) => {
    state.alertView = payload;
  },
  setAlertBar: (
    state: UIState,
    payload: {
      status: boolean;
      title: string;
      subtitle: string;
    },
  ) => {
    state.alertBar = payload;
  },

  setBottomSectionOffsetTop: (state: UIState, val: number) => {
    state.bottomSectionOffsetTop = val;
  },
};

/** @remarks
 * Use actions to call mutations functions in async way */
export const actions: ActionTree<UIState, any> = {};

/** @remarks
 * Use getter to get state data rather than direct access */
export const getters: GetterTree<UIState, any> = {
  isMap(state: UIState): boolean {
    return state.viewState === "map";
  },
  isMobile() {
    return isMobile();
  },
  isPC() {
    return isPC();
  },
  isTablet() {
    return isTablet();
  },
  iOS() {
    return iOS();
  },
  getBottomSectionOffsetTop(): number | undefined {
    return state.bottomSectionOffsetTop;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
