/**
 * @remarks
 * Vuex module for Authentication request
 **/
import { ActionTree, MutationTree } from "vuex";
import { AuthState } from "./@types/state-module";

function getTokenEmailFromLocalStorage(): string | undefined {
  const result: string | null = localStorage.getItem("session/tokens/email");
  if (result === null) return undefined;
  return result;
}
/** @remarks
 * The state data */
export const state: AuthState = {
  tokenEmail: getTokenEmailFromLocalStorage(),
};
/** @remarks
 * Use mutations to modify state data in sync way */
export const mutations: MutationTree<AuthState> = {
  setTokenEmail(state: AuthState, payload: string | undefined) {
    state.tokenEmail = payload;
    if (payload === undefined) {
      localStorage.removeItem("session/tokens/email");
    } else {
      localStorage.setItem("session/tokens/email", payload);
    }
  },
};
/** @remarks
 * Use actions to call mutations functions in async way */
export const actions: ActionTree<AuthState, any> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
