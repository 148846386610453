import {
  ResponseGetEvacuationDrillMode,
  FuncResponseGetEvacuationDrillMode,
} from "../@types/evacuation-drill";

export let isResponseGetEvacuationDrillMode: FuncResponseGetEvacuationDrillMode = function (
  mode: unknown
): mode is ResponseGetEvacuationDrillMode {
  return (
    typeof (mode as ResponseGetEvacuationDrillMode).placeIdHash === "string" &&
    typeof (mode as ResponseGetEvacuationDrillMode).isInEvacuationDrill ===
      "boolean"
  );
};
